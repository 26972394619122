











































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { CommonModule } from '@/store/modules/common'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)

@Component({
  name: 'vistor-history',
  components: {
    Table,
    Pagination,
    ImgView
  }
})
export default class extends Vue {
  $constants: any
  // 字典数据
  // 开门方式
  openDoorWayDicts: any = []
  searchModel = {
    key: 'enterTime',
    value: null
  }
  openDoorType = ''
  enterDeviceId = ''
  listLoading = false
  tableHeader = [
    {
      key: 'enterImg',
      name: '面部识别',
      width: '15%',
      isSpecial: true
    },
    {
      key: 'enterTime',
      name: '进入时间',
      width: '16%'
    },
    {
      key: 'openType',
      name: '开门方式',
      width: '15%',
      isSpecial: true
    },
    {
      key: 'enterPosition',
      name: '位置',
      width: '16%'
    },
    {
      key: 'enterOpenInfo',
      name: '开门信息',
      width: '16%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '16%',
      remark: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  imgUrl = ''

  // 获取门禁数据
  get doorGroups() {
    return CommonModule.doorGroups
  }

  async beforeCreate() {
    // 获取相关字典
    // 开门方式
    this.openDoorWayDicts = await getDicts('OPEN_DOOR_TYPE')
    CommonModule.GetDoorGroups()
  }

  created() {
    this.getVisitorList()
  }

  // 获取访客历史列表
  getVisitorList() {
    this.listLoading = true
    this.$api.visitor
      .getVisitorList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        openType: this.openDoorType !== '' ? Number(this.openDoorType) : '',
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        enterStartTime: this.searchModel.value
          ? (this.searchModel.value as any)[0]
          : '',
        enterEndTime: this.searchModel.value
          ? (this.searchModel.value as any)[1]
          : ''
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  // 批量导出
  doExport() {
    // TODO
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 返回
  goback() {
    this.$router.push('/visitors/visitor-list')
  }
}
